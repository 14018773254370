import React from 'react';
import P from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const Visit = ({ data }) => (
  <div className="call mt-2">
    <div className="call-box-top">
      <div className="call-phone">
        <strong>Besöksadress: </strong>
        <br />
        {data.site.siteMetadata.contact.address.street}
        <br />
        {data.site.siteMetadata.contact.address.zip}
        &nbsp;
        {data.site.siteMetadata.contact.address.city}
      </div>
    </div>
  </div>
);

Visit.propTypes = {
  data: P.shape({
    site: P.shape({
      siteMetadata: P.shape({
        contact: P.shape({
          address: P.shape({
            street: P.string.isRequired,
            zip: P.string.isRequired,
            city: P.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              address {
                street
                city
                zip
              }
            }
          }
        }
      }
    `}
    render={data => <Visit data={data} />}
  />
);
