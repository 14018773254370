import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';
import Visit from '../../components/Visit';

const Contact = () => (
  <Layout bodyClass="page-contact">
    <SEO title="Kontakta Innomenta" />
    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Kontakta oss</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button={false} />
          <Visit />
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
